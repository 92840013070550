import React from "react"
import { Image } from "react-bootstrap"
import Slider from "react-slick"

const AboutZespol = () => (
  <>

    <p className={"text-justify"}><b>Zespół Profil</b> to nasza młodzieżowa grupa turniejowa na poziomie zaawansowanym,
      która w
      ciągu roku
      reprezentuje Profil Dance Company na licznych zawodach rejonowych i ogólnopolskich. Występujemy w
      formacjach, mini formacjach oraz w prezentacjach indywidualnych – solo, duo, trio. W programie zespołu, poza
      regularnymi treningami, jest także taneczna sesja zdjęciowa, stworzenie etiudy teatru tańca, comiesięczne
      warsztaty z różnych stylów z wybitnymi tancerzami i choreografami, letni obóz treningowy, wystawienie
      spektaklu końcoworocznego.</p>

    <p>Dbamy o wszechstronny rozwój naszych tancerzy, dlatego oprócz zajęć tanecznych, uczestniczą także w
      specjalistycznym treningu Flexibility&Control oraz zajęciach AkroGim.</p>
    <Image src={require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2190.jpg")} style={{ width: "100%" }} rounded/>
    <p>
      Do Zespołu Profil, ze względu na ciągłość pracy, można dołączyć tylko na początku semestru. Obowiązują
      przesłuchania w postaci lekcji próbnej. Zapraszamy do kontaktu wszystkich chętnych tancerzy.</p>
    <h5 className={"text-center"}>Zespół Profil 2023</h5>
    <ZespolSlider photos={[
      require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/zespol2023/IMG_1436_Easy-Resize.com.jpg"),
      require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/zespol2023/IMG_2234_Easy-Resize.com.jpg"),
      require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/zespol2023/IMG_2271_Easy-Resize.com.jpg"),
      require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/zespol2023/IMG_2301_Easy-Resize.com.jpg"),
      require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/zespol2023/IMG_2349_Easy-Resize.com.jpg"),
      require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/zespol2023/IMG_2373_Easy-Resize.com.jpg"),
      require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/zespol2023/IMG_2406_Easy-Resize.com.jpg"),
      require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/zespol2023/IMG_2595_Easy-Resize.com.jpg"),
      require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/zespol2023/IMG_2606_Easy-Resize.com.jpg"),]
    }/>
  </>
)


const ZespolSlider = (props) => {
  const slideSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1325,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  }

  return (
    <Slider className={"mb-5 "} {...slideSettings}>
       {
        props.photos.map((value, idx )=> <Image src={value} style={{ maxWidth: "200px", transform: `scaleX(${idx % 2 === 0 ? 1 : -1})` }} rounded/>)
      }
    </Slider>
  )
}

export default AboutZespol
