import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ResponsiveSectionsNavigation from "../components/responsivesectionsnavigation"
import AboutProfil from "../components/aboutussections/AboutProfil"
import AboutInstruktorzy from "../components/aboutussections/AboutInstruktorzy"
import AboutZespol from "../components/aboutussections/AboutZespol"
import AboutOsiagniecia from "../components/aboutussections/AboutOsiagniecia"

const OnasPage = () => {
  return (
    <Layout pageInfo={{ pageName: "o-nas" }}>
      <SEO title="O nas"/>
      <h1 className={"text-center"}>O NAS</h1>
      <ResponsiveSectionsNavigation defaultActiveKey={"profil"}
                                    navigs={[
                                      ["profil", "Profil Dance Company", <AboutProfil/>],
                                      ["instruktorzy", "Instruktorzy", <AboutInstruktorzy/>],
                                      ["zespol", "Zespół Profil", <AboutZespol/>],
                                      ["osiagniecia", "Osiągnięcia", <AboutOsiagniecia/>],
                                    ]}
      />
    </Layout>
  )
}

export default OnasPage
