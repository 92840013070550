import { Container, Nav, Tab } from "react-bootstrap"
import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"
import React, { useEffect, useState } from "react"


const ResponsiveSectionsNavigation = (props) => {
  let [activeKey, setActiveKey] = useState(props.defaultActiveKey)
  useEffect(() => {
  const nextKey = (!window.location.hash || window.location.hash === "#") ? props.defaultActiveKey : window.location.hash.substr(1)
    setActiveKey(nextKey)
    props.currentLocationChanged && props.currentLocationChanged(props.navigs.filter(v => v[0] === nextKey)[0])
  }, typeof window !== "undefined" ? [window.location.hash] : [])


  return (
    <>
      {/*pc only*/}
      <Container className={"displaySm offernav"} style={{ position: "relative" }}>
        <Tab.Container id="left-tabs-example" activeKey={activeKey}>
          <div style={{ position: "fixed", width: "200px" }}>
            <Nav variant="pills" className="flex-column">
              {
                props.navigs.map((value, index) => {
                  return (<Nav.Item>
                    <Nav.Link key={index} eventKey={value[0]} href={"#" + value[0]}>{value[1]}</Nav.Link>
                  </Nav.Item>)
                })
              }
            </Nav>
          </div>

          <div style={{ marginLeft: "200px", padding: "15px" }}>
            <Tab.Content>
              {
                props.navigs.map((value, index) => {
                  return (
                    <Tab.Pane eventKey={value[0]}>
                      {value[2]}
                    </Tab.Pane>
                  )
                })
              }
            </Tab.Content>
          </div>
        </Tab.Container>
      </Container>

      {/*MOBILE ONLY*/}
      <Container className={"hideSm"}>
        <Accordion activeKey={activeKey} variant="black">
          {
            props.navigs.map((value, index) => {
              return (
                <Card bg={"brown"}>
                  <a href={"#" + value[0]} className={"link-no-style"}>
                    <Accordion.Toggle as={Card.Header}
                                      eventKey={value[0]} className={"bg-primary text-white"}>
                      {value[1]}
                    </Accordion.Toggle>
                  </a>
                  <Accordion.Collapse eventKey={value[0]}>
                    <Card.Body style={{ backgroundColor: "white" }}>{value[2]}</Card.Body>
                  </Accordion.Collapse>
                </Card>
              )
            })
          }
        </Accordion>
      </Container>
    </>
  )
}
export default ResponsiveSectionsNavigation
