import React from "react"
import { FaMedal } from "react-icons/fa"
import { GiTrophyCup } from "react-icons/gi"

const AboutOsiagniecia = () => (
  <div className={"text-justify"}>
    <div>
      <h3>Sezon 22/23</h3>
      <div className={"px-3"}>
        Osiągnięcia grupowe
        <div className={"px-3"}>
          <p><GiTrophyCup/> 2 miejsce na Zawodach Dance Challenge 2023 w kategorii Modern Formacje Dorośli</p>
          <p><GiTrophyCup/> 1 miejsce na Świątecznym Turnieju Tańca Sportowego Mokotów 2022 w kategorii Modern Formacje
            Dorośli zaawansowani</p>
          <p><GiTrophyCup/> Wyróżnienie na XV Ogólnopolskim Przeglądzie Zespołów Tanecznych o Puchar Burmistrza
            Grodziska Mazowieckiego w kategorii powyżej 15 lat</p>
          <p><GiTrophyCup/> 3 miejsce na Holiday Dance Festival w kategorii Formacje Modern &amp; Show Dance powyżej 16
            lat</p>
        </div>
      </div>
      <div className={"px-3"}>
        Osiągnięcia indywidualne
        <div className={"px-3"}>
          <p><FaMedal/> 4 miejsce na Polish Dance League, Modern &amp; Contemporary Solo 15-16 A+S (Maja Czerkas)</p>
          <p><FaMedal/> 1 miejsce na Holiday Dance Festival Solo Modern 15+ (Maja Czerkas)</p>
          <p><FaMedal/> 2 miejsce na Holiday Dance Festival Solo Modern 15+ (Ada Gołębiewska)</p>
          <p><FaMedal/> 3 miejsce na Holiday Dance Festival Solo Modern 15+ (Zuza Krupnik)</p>
          <p><FaMedal/> 3 miejsce na Świątecznym Turnieju Tańca Sportowego Mokotów w kategorii Modern Duety 2006-2009
            Open (Zuzia Krupnik &amp; Ada Gołębiewska)</p>
        </div>
      </div>
    </div>
    <div>
      <h3>Sezon 21/22</h3>
      <div className={"px-3"}>
        Osiągnięcia grupowe
        <div className={"px-3"}>
          <p><GiTrophyCup/> 3 miejsce na Holiday Dance Festival w kategorii Formacje Modern &amp; Show Dance powyżej 16
            lat</p>
          <p><GiTrophyCup/> 2 miejsce na XV Bemowskich Dniach Tanecznych w kategorii Modern Formacje Dorośli</p>
          <p><GiTrophyCup/> 2 miejsce na Ursynowskim Turnieju Tańca Sportowego w kategorii Modern Formacje Dorośli Open
          </p>
          <p><GiTrophyCup/> 2 miejsce na Tanecznych Wiktoriach Ogólnopolskim Przeglądzie Dziecięcych i Młodzieżowych
            Zespołów Tanecznych</p>
        </div>
      </div>
    </div>
    <div>
      <h3>Sezon 2019/2020</h3>
      <div className={"px-3"}>
        Osiągnięcia grupowe
        <div className={"px-3"}>
          <p><GiTrophyCup/> 1 miejsce na IV Ogólnopolskim Bielańskim Turnieju Tańca Nowoczesnego
            w kategorii Formacje Jazz/Modern Juniorzy 16+ lat</p>
          <p><GiTrophyCup/> 2 miejsce na Ogólnopolskim Festiwalu Tańca „Mazovia Open 2020” – Otwartych
            Mistrzostwach Mazowsza Federacji WADF w kategorii Mini Formacja Master Contemporary
            Ballet 16+</p>
          <p><GiTrophyCup/> 2 miejsce Ogólnopolskim Festiwalu Tańca „Mazovia Open 2020” – Otwartych Mistrzostwach
            Mazowsza Federacji WADF w kategorii Formacja Debiuty Contemporary Ballet 14-15 lat
            (Profil Prim)</p>
        </div>
      </div>
      <div className={"px-3"}>
        Osiągnięcia indywidualne
        <div className={"px-3"}>
          <p><FaMedal/> 5 miejsce na Ogólnopolskim Festiwalu Tańca „Mazovia Open 2020” – Otwartych
            Mistrzostwach Mazowsza Federacji WADF w kategorii Duet Master Contemporary Ballet 16+
            (Anna Kopeć i Aleksandra Emmel)</p>
          <p><FaMedal/> 6 miejsce na Ogólnopolskim Festiwalu Tańca „Mazovia Open 2020” – Otwartych
            Mistrzostwach Mazowsza Federacji WADF w kategorii Solo Master Contemporary Ballet 16+
            (Zofia Kalinowska)</p>
        </div>
      </div>
    </div>
    <div>
      <h3>Sezon 2018/2019</h3>
      <div className={"px-3"}>
        Osiągnięcia grupowe
        <div className={"px-3"}>
          <p><GiTrophyCup/> Grand Prix – Najlepsza Formacja na Bemowskim Turnieju Tanecznym „Spectrum“
            w kategorii Inne Formy Taneczne</p>
          <p><GiTrophyCup/> 1 miejsce na III Ogólnopolskim Bielańskim Turnieju Tańca Nowoczesnego
            w kategorii Formacje Jazz/Modern Juniorzy 12-15 lat</p>
          <p><GiTrophyCup/> 1 miejsce na Ogólnopolskim Festiwalu Amatorskich Zespołów Artystycznych „FAZA“ wkategorii
            Mini Formacje Modern
            Jazz Dorośli</p>
          <p><GiTrophyCup/> 1 miejsce na Ogólnopolskim Festiwalu Amatorskich Zespołów Artystycznych „FAZA“ w kategorii
            Formacje Modern Jazz Dorośli</p>
          <p><GiTrophyCup/> 3 miejsce na XIII Bemowskich Dniach Tanecznych w kategorii Formacje Debiuty 10-12 lat
            (Profil
            Prim)</p>
          <p><GiTrophyCup/> Wyróżnienie na I Ogólnopolskim Konkursie Tanecznym „Łódzka Scena Tańca“
            w kategorii Modern Jazz</p>
          <p><GiTrophyCup/> Wyróżnienie na XVI Ogólnopolskich Spotkaniach Tanecznych „Spontan“ 2019
            w kategorii Inne Propozycje Taneczne</p>
        </div>
        Osiągnięcia indywidualne
        <div className={"px-3"}>
          <p><FaMedal/> 1 miejsce na II Ogólnopolskim Festiwalu Tańca W Sulmierzycach w kategorii Solo Modern Jazz
            15+ lat (Anna Kopeć)</p>
          <p><FaMedal/> 1 miejsce na Ogólnopolskim Festiwalu Amatorskich Zespołów Artystycznych „FAZA“ w kategorii
            Solo Modern Jazz 14+ (Zofia Kalinowska)</p>
          <p><FaMedal/> 1 miejsce na Ogólnopolskim Festiwalu Amatorskich Zespołów Artystycznych „FAZA“ w kategorii
            Duety Modern Jazz Dorośli (Anna Kopeć i Aleksandra Emmel)</p>
          <p><FaMedal/> 1 miejsce na Śródmiejskim Festiwalu Młodych Talentów w kategorii Duety (Natalia Kulik i Basia
            Karasińska)</p>
          <p><FaMedal/> 2 miejsce na Bemowskim Turnieju Tanecznym „Spectrum” w kategorii Solo Inne Formy Tańca
            10-12 lat (Aleksandra Anioł)</p>
          <p><FaMedal/> 2 miejsce na Śródmiejskim Festiwalu Młodych Talentów w kategorii Solo (Sonia Łuczyńska)</p>
          <p><FaMedal/> 2 miejsce na Bemowskim Turnieju Tanecznym „Spectrum” w kategorii Duety Inne Formy Tańca
            12-15 lat (Natalia Kulik i Basia Karasińska)</p>
          <p><FaMedal/> 3 miejsce na Bemowskim Turnieju Tanecznym „Spectrum” w kategorii Duety Inne Formy Tańca
            16+ lat (Anna Kopeć i Aleksandra Emmel)</p>
          <p><FaMedal/> 3 miejsce na Ogólnopolskim Festiwalu Amatorskich Zespołów Artystycznych „FAZA“ w kategorii
            Solo Modern Jazz 14+ (Sonia Łuczyńska)</p>
          <p><FaMedal/> Wyróżnienie na XXVII Ogólnopolskim Przeglądzie „Taneczne Wiktorie” w kategorii Solo 16+ lat
            (Anna Kopeć)</p>
          <p><FaMedal/> Wyróżnienie na XXVII Ogólnopolskim Przeglądzie „Taneczne Wiktorie” w kategorii Solo 12-15 lat
            (Aleksandra Emmel)</p>
        </div>
      </div>
    </div>
  </div>
)

export default AboutOsiagniecia
