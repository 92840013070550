import React, { useState } from "react";
import { Button, Col, Image, Modal, Row } from "react-bootstrap";
import "./style.scss";

const EwaDescription = (<p>Założycielka, dyrektor artystyczny i główny choreograf Profil Dance Company.
  Studentka kierunku Choreografia i Techniki Tańca na Akademii Muzycznej w Łodzi.
  Dyplomowana instruktor tańca Polskiej Akademii Sportu oraz certyfikowany
  wychowawca obozowy Instytutu Doskonalenia Kadr Pedagogicznych. Dodatkowo,
  absolwentka Uniwersytetu Warszawskiego po kierunku Logistyka Mediów.
  <br/>
  <br/>
  W naszej szkole tańca to ona jest szefem wszystkich szefów i wraz z jej prawą ręką –
  Gosią, trzyma pieczę nad rozwojem wszystkich grup zespołowych. Specjalizuje się
  w technikach modern jazz, jazz i contemporary. Prowadzi także zajęcia Baby Ballet,
  Flexibility&Control oraz AkroGim. Jako instruktor jest wymagająca i ambitna.
  Zawsze przychodzi do pracy z mnóstwem pozytywnej energii i celem, do którego dąży. Jej choreografie cechuje przede
  wszystkim oryginalny
  styl i koncept, niecodzienna wrażliwość muzyczna i estetyka oraz spójność produkcji. Od kiedy pamięta jej pasją był
  taniec i wymyślanie
  układów a największym marzeniem – otworzenie własnej szkoły tańca i wychowywanie młodych pokoleń.
  <br/>
  <br/>

  Ewa przez wiele lat kształtowała swój warsztat taneczny pod okiem Anny Kepal, jako tancerka Zespołu Tańca Jazzowego
  Mirage, wraz z
  którym zdobyła wiele osiągnięć na turniejach tanecznych. Ponadto brała udział w licznych zajęciach, warsztatach i
  obozach treningowych,
  które wspomagały jej rozwój. Obecnie nadal doskonali swoje umiejętności taneczne i choreograficzne na studiach
  artystycznych i
  dodatkowych kursach instruktorskich. Ciągły rozwój jest dla niej niezwykle ważny i inspirujący, dlatego nieustannie
  szkoli się w technice
  tańca klasycznego, współczesnego i jazzowego, metodyce prowadzenia zajęć, stretchingu, improwizacji ruchowej,
  akrobatyce sportowej, oraz
  wielu innych stylach, m.in. commercial dance, ladies style, brodway jazz.</p>)

const GosiaDescription = (<p>
    Założycielka i choreograf Profil Dance Company. Instruktor tańca oraz instruktor sportu o specjalności fitness Polskiej Akademii Sportu,
    certyfikowany wychowawca Instytutu Doskonalenia Kadr Pedagogicznych. Poza tym, absolwentka Uniwersytetu Warszawskiego na kierunku
    Lingwistyka stosowana niemiecko-angielska.<br/><br/>
    Gosia jest nie tylko dobrym instruktorem, ale przede wszystkim pedagogiem, co odziedziczyła w genach po mamie. Zawsze zależy jej na tym,
    aby każdy czuł się dobrze na zajęciach, co pozwala młodym tancerzom na niesamowity rozwój. Specjalizuje się w modern jazzie i technikach
    pokrewnych oraz akrobatyce. W naszej szkole prowadzi zarówno zajęcia grupowe z tańca, techniki i akrobatyki, jak i zajęcia indywidualne.
    Jako instruktor z uśmiechem motywuje, ale też wymaga od uczniów samodyscypliny. Sama jest sumienna i zaangażowana, bo twierdzi, że bez
    tych cech ciężko osiągnąć sukces.<br/><br/>
    Swoją przygodę z ruchem rozpoczęła jako 7-latka na zajęciach z akrobatyki, którą trenowała w Pałacu Młodzieży. Następnie przez wiele lat,
    jako tancerka Zespołu Tańca Jazzowego Mirage, kształtowała swój warsztat taneczny pod okiem Anny Kepal. Wraz z zespołem zdobyła wiele
    nagród na turniejach tanecznych. Wciąż dba o swój rozwój, biorąc udział w zajęciach, obozach treningowych i warsztatach tanecznych dla
    dorosłych. Wie, jak ważna jest wszechstronność, dlatego nadal szkoli się w metodyce prowadzenia zajęć, improwizacji ruchowej, stretchingu
    oraz treningach funkcjonalnych dla tancerzy.
  </p>

)

const TBA= "Niebawem pojawi się tutaj coś więcej :) ..."
const AleksandraEmmel = "Wieloletnia tancerka z sukcesami na arenie Polskiej i międzynarodowej. Swoją przygodę z tańcem i akrobatyką zaczęła w wieku 4 lat i zdobyła takie osiągnięcia jak: II-Vicemistrzostwo Świata federacji WADF, 7 miejsce na Mistrzostwach Świata IDO, Mistrzostwo Polski federacji WADF i 4 miejsce na Mistrzostwach Polski IDO. Specjalizuje się w stylach tańca contemporary i jazz, ale również rozwija swoje umiejętności w stylach commercial, highheels i hiphop. Szkoliła się u wielu instruktorów z Polski i zagranicy, uczestniczyła w takich programach tanecznych jak Base Camp 360 i Johanson Dance Academy w Londynie, heelsbylala i Hit The Stage by Tomek Prządka. Od 5 lat tancerka zespołu J.TEAM w Egurrola Dance Studio. Uwielbia pracę z dziećmi i przekazywanie im swojej pasji do tańca i akrobatyki, jest kwalifikowanym instruktorem tańca i wychowawcą. Studiuje fizjoterapię na Akademii Wychowania Fizycznego w Warszawie."
const KrzysztofDescription = ""
const AsiaDescription = "Instruktor tańca Polskiej Akademii Sportu oraz wychowawca kolonijny. Studentka 5 roku psychologii na Uniwersytecie Kardynała Stefana Wyszyńskiego w Warszawie. Przygodę z tańcem rozpoczęła od tańca towarzyskiego w szkole Anny Głogowskiej i Marcina Wrzesińskiego. Przez dziesięć lat osiągała sukcesy w turniejach klasy ogólnopolskiej w tańcach standardowych i latynoamerykańskich. Następnie zainteresowała się tańcem modern jazz, który pokochała w zespole tanecznym Mirage w Pałacu Młodzieży. Ponadto w swojej historii z tańcem próbowała różnych stylów tanecznych od tańca klasycznego po zouka brazylijskiego. Przygodę z pierwszym tańcem zaczęła od nauczenia układu swojego męża na własne wesele. Łącząc wiedzę z różnorodnych stylów i doświadczenie pracy instruktora tańca potrafi stworzyć piękną choreografię na pierwszy taniec, która zachwyci zarówno rodziców jak i świadków."

const AdriannaDescription = `Certyfikowany instruktor tańca Placówki Kształcenia Ustawicznego. Ukończyła szkolenie animatorów
zabaw sportowych i rekreacyjnych. Ponadto studentka Szkoły Głównej Gospodarstwa Wiejskiego na
kierunku Turystyka i Rekreacja. Uzdolniona tancerka z doświadczeniem scenicznym i turniejowym.
Ma obycie z wieloma stylami tanecznymi również z disco dance oraz hip-hop’em. Przez wiele lat
szkoliła się w pracy z dziećmi i młodzieżą – najpierw jako asystentka na zajęciach tanecznych, teraz
jako samodzielny instruktor. Do pracy podchodzi z uśmiechem i wielkim zaangażowaniem by swoją
wiedzę i umiejętności przekazywać naszym uczestnikom.`

const WiktoriaDescription = `Tancerka od lat dziecięcych, wielokrotnie nagradzana na krajowych oraz międzynarodowych
turniejach tanecznych. Zdobyła Mistrzostwo Polski jeszcze jako nastolatka a następnie rozwijała się w
spektaklach tanecznych tworzonych przez wybitnych choreografów. W ostatnim czasie uczestniczka
Teatru Tańca Jazzgot pod okiem Anety Bułki występowała licznie na deskach teatrów w Polsce.
Uzdolniony instruktor tańca z doświadczeniem w pracy z dziećmi. Pełna energii i uśmiechu do
poszerzania własnych umiejętności.`

const MagdalenaDescription = `Magda od małego szkoliła się w wielu szkołach i studiach tanecznych. Zarówno solo jak i w grupie
zdobywała liczne nagrody na konkursach, pokazach i zawodach tanecznych na różnych szczeblach.
Specjalizuje się w tańcu jazzowym oraz modern. Wystąpiła m.in. w musicalu "Fame" oraz koncercie
"L'Chaim" Samodzielnego Zespołu Teatralnego Organizowanego w Staszicu SZTOS, w spektaklu "I
love Moniuszku" w Teatrze Wielkim Operze Narodowej w Warszawie, w produkcjach telewizyjnych tj.
XXII Mazurska Noc Kabaretowa, a także w wielu różnego rodzaju filmowo-teatralnych projektach.
Oprócz tego studiuje fizjoterapię na warszawskim AWF oraz jest instruktorem tańca. Zdjęcie wykonał Jan Novak-Zempliński.`


const instructors = [
  {
    name: "Ewa Konecka",
    src: require("../../images/do_100/profil-529_optimized_Easy-Resize.com (1).jpg"),
    description: EwaDescription,
  },
  {
    name: "Gosia Woszczyk",
    src: require("../../images/do_100/profil-536_optimized_Easy-Resize.com (1).jpg"),
    description: GosiaDescription,
  },
  {
    name: "Adrianna Sowińska",
    src: require("../../images/do_100/Adrianna.jpg"),
    description: AdriannaDescription,
  },
  {
    name: "Wiktoria Sobocińska",
    src: require("../../images/do_100/Wiktoria.jpg"),
    description: WiktoriaDescription,
  },
  {
    name: "Magdalena Olędzka",
    src: require("../../images/do_100/Magdalena.jpg"),
    description: MagdalenaDescription,
  },
  {
    name: "Joanna Markiewicz-Wójcik",
    src: require("../../images/do_100/Joanna Markiewicz_2.jpg"),
    description: AsiaDescription,
  },
  {
    name: "Krzysztof Ciszewski",
    src: require("../../images/do_100/krzysiek2.jpg"),
    description: KrzysztofDescription,
  },
  {
    name: "Aleksandra Emmel",
    src: require("../../images/do_100/AleksandraEmmel.jpg"),
    description: AleksandraEmmel
  },
  {
    name: "Inga Sobocińska",
    src: require("../../images/do_100/kotek_intruktorka.jpg"),
    description: TBA
  },
  {
    name: "Dominika Blezien",
    src: require("../../images/do_100/kotek_intruktorka.jpg"),
    description: TBA
  },
];

const AboutInstruktorzy = () => (
  <>
    <Row>
      {instructors.map((instructor, index) => (
        <Instruktor key={index} {...instructor} />
      ))}
    </Row>
  </>
);

const Instruktor = (props) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Col
        lg={props.centered ? { span: 6, offset: 3 } : 6}
        className={"instruktor"}
        onClick={() => setModalShow(true)}
      >
        <div className={"instruktorImage"}>
          <Image
            src={props.src}
            className="instruktorImageimage"
            roundedCircle
          />
          <div className="overlay text-center text-brown">+</div>
        </div>
        <h4 className={"text-center pt-3 pb-3"}>{props.name}</h4>
      </Col>
      <InstruktorModal show={modalShow} onHide={() => setModalShow(false)} {...props} />
    </>
  );
};

function InstruktorModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={"modalInstruktor"}
    >
      <Modal.Header closeButton>
        <Image src={props.src} className="modalInstruktorImage" roundedCircle />
      </Modal.Header>
      <Modal.Body>
        <br />
        <h4>{props.name}</h4>
        <p className={"text-justify"}>{props.description}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Zamknij</Button>
      </Modal.Footer>
    </Modal>
  );
}


export default AboutInstruktorzy;
