import React from "react"
import { Image, Row, Col } from "react-bootstrap"
import { FaRegSmileBeam } from "react-icons/fa"

const AboutProfil = () => (
  <>
    <Row>
      <Col lg={8}>
        <p className={"text-justify"}><b>Profil Dance</b> to szkoła tańca założona przez Ewę Konecką, działająca na terenie Warszawy.
          Profil powstał w 2014 roku i początkowo funkcjonował jako zespół
          taneczny składający się z zaprzyjaźnionych instruktorek tańca –
          obecnie <b>Profil Oldschool</b>. W wyniku ogromnej pasji i chęci rozwijania
          młodych talentów w 2018 roku rozszerzyliśmy naszą ofertę o zajęcia
          dla dzieci i młodzieży. Otworzyliśmy wówczas grupy <b>Profil Mini</b>, {" "}
          <b>Profil Prim</b> oraz grupę turniejową - <b>Zespół Profil</b>. Spośród innych
          szkół wyróżnia nas indywidualne podejście do uczestnika, przyjazna
          atmosfera i oryginalność choreograficzna naszych instruktorów.</p>

        <p className={"text-justify"}>
          Na co dzień specjalizujemy się w technikach <b>modern, jazz i
          contemporary</b>. Prowadzimy zajęcia na różnych poziomach
          zaawansowania – od grup początkujących do zaawansowanych.
          Dodatkowo nasza szkoła oferuje zajęcia akrobatyczno-gimnastyczne
          {" "} <b>AkroGim</b>, taniec klasyczny dla najmłodszych – <b>Baby Dance</b> oraz
          specjalistyczne treningi wzmacniająco-rozciągające <b>Flexibility&Control</b> dla najbardziej zaawansowanych
          tancerzy.
        </p>

        <p className={"text-justify"}>
          Nasze grupy zespołowe występują na pokazach i <b>turniejach
          tanecznych</b> zdobywając liczne nagrody i wyróżnienia (zobacz
          więcej w zakładce osiągnięcia). Zwieńczeniem całorocznej pracy
          Profil Dance Company jest <b>spektakl taneczny</b>, podczas którego
          występują na scenie wszyscy uczestnicy zajęć w przygotowanych w
          ciągu roku prezentacjach.
        </p>
        <p className={"text-right"}>
          Zapraszamy do dołączenia do naszej profilowej rodziny,
        </p>
        <p className={"text-right"}>
          Profilowy Team <FaRegSmileBeam/>
        </p>

      </Col>
      <Col lg={4}>
        <Image src={require("../../images/do_100/profil-540_optimized.jpg")} style={{width: "100%"}} rounded/>
      </Col>
    </Row>
  </>
)

export default AboutProfil
